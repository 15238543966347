'use client';

import React, { useState, useEffect, useRef } from 'react';
import StyledMarkdown from '@/components/styled-markdown';

interface AnimatedTypingMarkdownProps {
  text: string;
  className?: string;
}

const AnimatedTypingMarkdown: React.FC<AnimatedTypingMarkdownProps> = ({ text, className }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayedText(text.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 5); // Adjust speed as needed

    return () => clearInterval(intervalId);
  }, [text, isVisible]);

  return (
    <div
      ref={ref}
      className={className}
    >
      <StyledMarkdown className="prose-sm max-w-none">{displayedText}</StyledMarkdown>
    </div>
  );
};

export default AnimatedTypingMarkdown;
