// components/HeroMotion.tsx
'use client'

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ThemeSwitcher } from './theme-switcher';

interface HeroMotionProps {
  children: React.ReactNode;
}

const Dot = () => {
  const [theme, setTheme] = useState('light');
  const [textShadowStyle, setTextShadowStyle] = useState('1em 2em 7px hsla(13, 100%, 50%, 0.9)`');
  useEffect(() => {
    const hue = Math.random() * 360;
    const startX = -.5 + Math.random() * 1;
    const startY = -.5 + Math.random() * 1;
    const textShadowStyle = `${startX}em ${startY}em 7px hsla(${hue}, 100%, 50%, 0.9)`;
    setTextShadowStyle(textShadowStyle);
  }, []);
  const duration = Math.random() * 10 + 10;
  const delay = Math.random() * 10 + 10;

  return (
    <motion.div
      className="absolute rounded-full"
      style={{
        textShadow: textShadowStyle,
        left: `50%`,
        top: `50%`,
        color: 'transparent',
        content: '.',
        fontSize: '42px',
        mixBlendMode: theme == "dark" ? "screen" : "multiply",
      }}
      animate={{
        scale: [7, 15],
        rotate: [0, 360],
        translateX: [`-20px`, `20px`],
      }}
      transition={{
        duration: duration,
        repeat: Infinity,
        repeatType: 'reverse',
        ease: 'easeInOut',
        delay: -delay,
      }}
    >.
    <ThemeSwitcher onThemeChange={setTheme} />
    </motion.div>
  );
};

const DotLayer = ({ count }: { count: number; }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Dot key={index} />
      ))}
    </>
  );
};

export const HeroMotion: React.FC<HeroMotionProps> = ({ children }) => {
  return (
    <div className="relative overflow-hidden bg-base-200">
      <div className="absolute inset-0">
        <DotLayer count={8} />
      </div>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};
