'use client';

import React, { useState, useEffect, useRef } from 'react';
import { Text } from '@/components/text';
import AnimatedTyping from '@/components/animated-typing';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { AcademicCapIcon } from "@heroicons/react/20/solid";

interface ComparisonExample {
  title: string;
  caption: string;
  question: string;
  chatgptAnswer: string;
  caseyAnswer: string;
}

interface ComparisonCarouselProps {
  examples: ComparisonExample[];
}

const ComparisonCarousel: React.FC<ComparisonCarouselProps> = ({ examples }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = slideRefs.current.findIndex((ref) => ref === entry.target);
            if (index !== -1) {
              setActiveSlide(index);
            }
          }
        });
      },
      { threshold: [0.0, 0.25, 0.5, 0.75, 1.0] }
    );

    slideRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const scrollToSlide = (index: number) => {
    slideRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };

  const nextSlide = () => {
    const nextIndex = (activeSlide + 1) % examples.length;
    scrollToSlide(nextIndex);
  };

  const prevSlide = () => {
    const prevIndex = (activeSlide - 1 + examples.length) % examples.length;
    scrollToSlide(prevIndex);
  };

  return (
    <div className="container mx-auto px-4 md:px-8 lg:px-16">
      <h2 className="text-3xl font-bold mb-12 text-center">Casey vs. ChatGPT</h2>
      <div className="sticky z-50 top-1/2 py-4 transform -translate-y-1/2 h-0 mx-4 lg:-mx-16 -mt-8">
        <div className="flex justify-between mx-auto">
          <button onClick={prevSlide} className="btn btn-circle btn-neutral">❮</button>
          <button onClick={nextSlide} className="btn btn-circle btn-neutral">❯</button>
        </div>
      </div>
      <div ref={carouselRef} className="carousel w-full space-x-4">
        {examples.map((example, index) => (
          <div
            key={index}
            ref={(el) => {
              slideRefs.current[index] = el;
            }}
            className="carousel-item w-full flex-shrink-0"
          >
            <div className="w-full space-y-8">
              <div className="text-center">
                <Text variant='h2'>
                  <CheckCircleIcon className="w-10 h-10 inline-block mr-2 text-success" />
                  {example.caption}
                </Text>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-base-100 p-6 rounded-box shadow-lg">
                  <Text variant='h2' className='mb-4'>
                    <AcademicCapIcon className="h-6 w-6 mr-2 inline-block text-primary" />
                    Casey
                  </Text>
                  <Text variant='h3' className="mb-4">Q: {example.question}</Text>
                  <AnimatedTyping text={example.caseyAnswer} />
                </div>
                <div className="bg-base-300 p-6 rounded-box shadow-lg text-base-content/70">
                  <Text variant='h2' className="mb-4 text-base-content/70">ChatGPT</Text>
                  <Text variant='h3' className="mb-4 text-base-content/70">Q: {example.question}</Text>
                  <AnimatedTyping text={example.chatgptAnswer} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonCarousel;
