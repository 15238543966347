// app/ValueProp.js
'use client'

import { useState, useEffect } from 'react';
import { Text } from '@/components/text';
import { ThemeSwitcher } from './theme-switcher';
import Image from 'next/image';

export function LandingValueProp({
  valueProp,
  arrangement
  , ...props
} : React.ComponentPropsWithoutRef<'div'> & {
  valueProp: {
    icon: React.JSX.Element;
    title: string;
    description: string;
    image: string;
  },
  arrangement: "left" | "right";
}) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <div {...props}>
      <div className="flex flex-col lg:flex-row items-stretch lg:items-start gap-8 lg:gap-24">
        <div className={`lg:w-2/3 order-1 ${arrangement == "left" ? 'lg:order-1' : 'lg:order-2'}`}>
          <Image
            width={2400}
            height={1350}
            src={`/screenshots/${valueProp.image}${isDarkMode ? '-dark' : ''}.png`}
            alt={valueProp.title}
            className="w-full rounded-lg shadow-2xl shadow-base-content/40 transition-all duration-300 hover:scale-105"
          />
        </div>
        <div className={`lg:w-1/3 order-2 ${arrangement == "left"  ? 'lg:order-2' : 'lg:order-1'}`}>
          <div className="flex flex-col items-start">
            {valueProp.icon}
            <Text variant="h2" className="font-bold">{valueProp.title}</Text>
            <Text variant="p" className='mb-12'>{valueProp.description}</Text>
          </div>
        </div>
      </div>
      <ThemeSwitcher onThemeChange={t => setIsDarkMode(t == 'dark')} />
    </div>
  );
}
