import React, { ReactNode } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';
import { Plugin, Transformer } from 'unified';
import Link from "next/link";
import { type Node } from 'unist';

interface DirectiveNode extends Node {
  type: 'textDirective' | 'leafDirective' | 'containerDirective';
  name: string;
  attributes?: Record<string, string>;
  data?: {
    hName?: string;
    hProperties?: Record<string, unknown>;
  };
}

const generateSlug = (str: string) => {
  str = str?.replace(/^\s+|\s+$/g, '');
  str = str?.toLowerCase();
  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    ?.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
};

const getSlugFromNode = (node: ReactNode): string => {
  if (typeof node === 'string') {
    return generateSlug(node);
  } else if (Array.isArray(node)) {
    return node.map(getSlugFromNode).join('-');
  } else if (React.isValidElement(node) && node.props.children) {
    return getSlugFromNode(node.props.children);
  } else {
    return '';
  }
};

export const components: Partial<Components> = {
  a: ({ ...props }) => (
    <Link
      target={props.target || props.href?.startsWith("#") ? "_self" : "_blank"}
      rel="noopener noreferrer"
      href={props.href || "#"}
      {...props}
    />
  ),
  h1: ({ children, ...props }) => (
    <h1 id={getSlugFromNode(children)} {...props}>
      {children}
    </h1>
  ),
  h2: ({ children, ...props }) => (
    <h2 id={getSlugFromNode(children)} {...props}>
      {children}
    </h2>
  ),
  h3: ({ children, ...props }) => (
    <h3 id={getSlugFromNode(children)} {...props}>
      {children}
    </h3>
  ),
};

const remarkHighlight: Plugin = () => {
  const transformer: Transformer = (tree) => {
    visit(tree, (node: Node) => {
      if (
        (node.type === 'textDirective' ||
          node.type === 'leafDirective' ||
          node.type === 'containerDirective') &&
        'name' in node
      ) {
        const directiveNode = node as DirectiveNode;
        if (directiveNode.name === 'highlight') {
          const attributes = directiveNode.attributes || {};
          if (attributes.class) {
            const data = directiveNode.data || (directiveNode.data = {});
            data.hName = 'span';
            data.hProperties = { className: attributes.class };
          }
          // If no class is specified, we don't modify the node
        }
      }
    });
  };
  return transformer;
};

interface StyledMarkdownProps {
  children: ReactNode;
  className?: string;
}

const StyledMarkdown: React.FC<StyledMarkdownProps> = ({ children, className }) => {
  const content = typeof children === 'string' ? (
    <ReactMarkdown
      className={`prose dark:prose-invert prose-a:link prose-a:link-secondary prose-a:link-hover ${className}`}
      remarkPlugins={[remarkGfm, remarkDirective, remarkHighlight]}
      components={components}
    >
      {children}
    </ReactMarkdown>
  ) : (
    children
  );

  return <>{content}</>;
};

export default StyledMarkdown;
