import clsx from 'clsx';
import React from 'react';
import { match } from 'ts-pattern';

interface TextProps {
  children: React.ReactNode;
  variant?: "title" | "h1" | "h2" | "h3" | "p" | 'small' | "tiny" | "inline";
  className?: string;
}

export const Text: React.FC<TextProps> = function({
  children,
  variant = "p",
  className
}) {
  const Component = match(variant)
    .with("title", () => "h1")
    .with("h1", () => "h1")
    .with("h2", () => "h2")
    .with("h3", () => "h3")
    .with("p", () => "p")
    .with("small", () => "p")
    .with("tiny", () => "p")
    .with("inline", () => "span")
    .otherwise(() => "p") as keyof JSX.IntrinsicElements;

  return (
    <Component
      className={clsx(
        'tracking-tight text-base-content',
        match(variant)
          .with("title", () => "text-4xl font-bold mt-8 mb-1 leading-tight")
          .with("h1", () => "text-3xl font-semibold mt-6 mb-1 leading-tight")
          .with("h2", () => "text-2xl font-semibold mt-5 mb-1 leading-snug")
          .with("h3", () => "text-xl font-semibold mt-4 mb-1 leading-snug")
          .with("p", () => "text-base mt-0 mb-4")
          .with("small", () => "text-sm mt-0 mb-2")
          .with("tiny", () => "text-xs text-base-content/70 mt-0 mb-1")
          .with("inline", () => "inline")
          .otherwise(() => ""),
        className
      )}
    >
      {children}
    </Component>
  );
}

export default Text;
