import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/casey-scholar-app/components/comparison-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroMotion"] */ "/vercel/path0/casey-scholar-app/components/hero-motion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/casey-scholar-app/components/landing-action-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/casey-scholar-app/components/landing-pricing-tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LandingValueProp"] */ "/vercel/path0/casey-scholar-app/components/landing-value-props.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/casey-scholar-app/node_modules/next/dist/client/link.js");
