// app/ThemeSwitcher.js
'use client'

import { useEffect } from 'react';

export function ThemeSwitcher(
  {
    onThemeChange
  } : {
    onThemeChange: (theme: string) => void;
  }
) {

  useEffect(() => {
    const updateTheme = () => {
      const dataTheme = document.documentElement.getAttribute('data-theme');
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const theme = prefersDark ? 'dark' : (dataTheme || 'light');
      onThemeChange(theme);
    };

    updateTheme();

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateTheme);

    return () => {
      observer.disconnect();
      mediaQuery.removeEventListener('change', updateTheme);
    };
  }, [onThemeChange]);

  return (
    <div>
      {/* Theme switcher UI */}
    </div>
  );
}
