"use client";
import Link from 'next/link';
import { ProfileContext } from './profile-context';
import { useContext } from 'react';
import { usePostHog } from 'posthog-js/react';

export default function LandingActionButton({ ...props }) {
  const { profile } = useContext(ProfileContext);
  const posthog = usePostHog();
  if (profile) {
    return <Link href="/dashboard" {...props}>Go to dashboard</Link>
  } else {
    return <Link href="/signin/signin" {...props}>Get started</Link>
  }
}
