'use client';

import React, { useState, useContext, useCallback } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Button } from '@headlessui/react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { ProfileContext } from './profile-context';
import clsx from 'clsx';
import { checkoutWithStripe } from '@/lib/subscription/actions';
import { useRouter } from 'next/navigation';
import { getStripe } from '@/lib/stripe/client';
import { type PricingPlan, type Interval  } from '@/lib/subscription/actions';

const PricingCard = ({
  plan,
  isPopular,
  interval,
  size = 'lg',
  isCurrentPlan = false,
} : {
  plan: PricingPlan,
  isPopular: boolean,
  interval: Interval,
  size?: 'sm' | 'lg',
  isCurrentPlan?: boolean
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const router = useRouter();
  const price = plan.priceByInterval[interval];
  const { isLoading: profileIsLoading, profile } = useContext(ProfileContext);
  const pricingButtonClick = useCallback(() => {
    const asyncAction = async () => {
      setIsLoading(true);
      if (price.unit_amount === 0) {
        router.push(`/dashboard`);
        return;
      }
      if (!profileIsLoading && profile) {
        const { errorRedirect, sessionId } = await checkoutWithStripe(profile, plan.priceByInterval[interval].id!);
        if (errorRedirect) {
          router.push(errorRedirect);
        }
        if (sessionId) {
          const stripe = await getStripe();
          if (stripe) {
            await stripe.redirectToCheckout({ sessionId });
          } else {
            // show some kind of error message
          }
        }
      } else {
        const accountUrl = `/dashboard/account?subscribeToPlan=${plan.id}`;
        router.push(`/signin/signin?next=${encodeURIComponent(accountUrl)}`);
      }
      setIsLoading(false);
    };
    asyncAction();
  }, [profile, router, interval, plan.id, plan.priceByInterval, price.unit_amount, profileIsLoading]);
  return (
    <div className={clsx(
      "card bg-base-100 border-2",
      {
        "lg": "w-96 shadow-xl",
        "sm": "w-72 shadow-md"
      }[size],
      isPopular ? 'border-primary' : 'border-base-content/20'
    )}>
      <div className="card-body">
        <h2 className="card-title">{plan.name}</h2>
        {isPopular && (
          <div className="badge badge-primary">Most Popular</div>
        )}
        <p className="text-3xl font-bold">
          ${price.unit_amount / 100}
          {interval && price.unit_amount > 0 && <span className="text-xl font-normal">/{interval}</span>}
        </p>
        <ul className="space-y-2">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <CheckIcon className="w-5 h-5 mr-2 text-primary" />
              {feature}
            </li>
          ))}
        </ul>
        <div className="card-actions mt-4">
          <Button
            className="btn btn-primary btn-outline w-full"
            onClick={pricingButtonClick}
            disabled={isLoading || isCurrentPlan}
          >
            {isCurrentPlan
              ? "Your Current Plan"
              : (price.unit_amount === 0 ? 'Get Started' : 'Subscribe')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const PricingTabs = ({
  plans,
  size = 'lg',
  currentPlanId = undefined,
} : {
  plans: PricingPlan[],
  size?: 'sm' | 'lg',
  currentPlanId?: string | undefined;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <TabList className={clsx("flex justify-center space-x-8 mb-8")}>
        <Tab className={({selected}) => clsx(
          "btn btn-primary btn-outline w-32",
          size === 'sm' && 'btn-sm',
          selected && "btn-active"
        )}>
          Monthly
        </Tab>
        <Tab className={({selected}) => clsx(
          "btn btn-primary btn-outline w-32",
          size === 'sm' && 'btn-sm',
          selected && "btn-active"
        )}>
          Yearly
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <div className="flex flex-wrap justify-center gap-8">
            {plans.map((plan, index) => (
              <PricingCard
                key={plan.id}
                plan={plan}
                isPopular={index === 1}
                interval="month"
                size={size}
                isCurrentPlan={currentPlanId === plan.id}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex flex-wrap justify-center gap-8">
            {plans.map((plan, index) => (
              <PricingCard
                key={plan.id}
                plan={plan}
                isPopular={index === 1}
                interval="year"
                size={size}
                isCurrentPlan={currentPlanId === plan.id}
              />
            ))}
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default PricingTabs;
